import React from "react";
import Seo from "../../components/seo";
import Layout from "../../components/Layout";
import HeroSection from "../../components/ContactUs/HeroSection";
import FrequentlyAskQuestionSection from "../../components/ContactUs/FrequentlyAskQuestionSection";
import LocationSection from "../../components/ContactUs/LocationSection";
import LatestInformationSection from "../../components/ContactUs/LatestInformationSection";

import { graphql } from "gatsby"
import get from "lodash/get"


const ContactUs= ({  data, location }) => {

    const pageData = get(data, "prismicContactUs.dataRaw", {});
    const pageData_1 = get(data, "prismicLastInformationSection.dataRaw", {});

    const hero = {
        hero_title: get(pageData, "hero_title", []),
        hero_description: get(pageData, "hero_description", []),
        hero_contact_form_title: get(pageData, "hero_contact_form_title", ""),
        hero_tel: get(pageData, "hero_tel", ""),
        hero_contact_form_mobile_icon: get(pageData, "hero_contact_form_mobile_icon.url", ""),
        hero_contact_form_mobile_icon_alt: get(pageData, "hero_contact_form_mobile_icon.alt", ""),
        hero_contact_form_logo: get(pageData, "hero_contact_form_logo.url", ""),
        hero_contact_form_logo_alt: get(pageData, "hero_contact_form_logo.alt", ""),
        hero_email: get(pageData, "hero_email", ""),
        hero_contact_form_email_icon: get(pageData, "hero_contact_form_email_icon.url", ""),
        hero_contact_form_email_icon_alt: get(pageData, "hero_contact_form_email_icon.alt", ""),
        hero_contact_form_description: get(pageData, "hero_contact_form_description", ""),
    }

    const faq = {
        faq_top: get(pageData, "faq_top", []),
        faq_title: get(pageData, "faq_title", []),
        faq_description: get(pageData, "faq_description", ""),
        faq_card: get(pageData, "faq_card", ""),
        person_a: get(pageData, "person_a.url", ""),
        person_b: get(pageData, "person_b.url", ""),
        person_c: get(pageData, "person_c.url", ""),
        bottom_description: get(pageData, "bottom_description", ""),
        buttom_title: get(pageData, "buttom_title", ""),
        button_text: get(pageData, "button_text", ""),
    }

    const last ={
        title: get(pageData_1, "title", []),
        side_image: get(pageData_1, "side_image.url", []),
        description: get(pageData_1, "description", []),
        button_text: get(pageData_1, "button_text", []),
    }

    return (
        <Layout location={location} title={''}>
            <HeroSection  data={hero}/>
            <LocationSection/>
            <LatestInformationSection  data={last}/>
            <FrequentlyAskQuestionSection  data={faq}/>
        </Layout>
    );
};

export const Head = ({ data }) => {
    return <Seo title={'Contact Us'} description={'excerpt'} />;
};
export default ContactUs;

export const query = graphql`
  query seoPersonalInsuranceQuery{
    prismicContactUs{
      dataRaw
    }
    prismicLastInformationSection{
      dataRaw
    }
  }
`