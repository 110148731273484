// extracted by mini-css-extract-plugin
export var frequently_question_section = "index-module--frequently_question_section--3884e";
export var frequently_question_section_area = "index-module--frequently_question_section_area--ef2a5";
export var frequently_question_section_area_faqs = "index-module--frequently_question_section_area_faqs--74bca";
export var frequently_question_section_area_sub = "index-module--frequently_question_section_area_sub--3e42f";
export var frequently_question_section_area_title = "index-module--frequently_question_section_area_title--d918e";
export var frequently_question_still_question_section = "index-module--frequently_question_still_question_section--4c626";
export var frequently_question_still_question_section_button = "index-module--frequently_question_still_question_section_button--d947a";
export var frequently_question_still_question_section_button_arrow = "index-module--frequently_question_still_question_section_button_arrow--25ba0";
export var frequently_question_still_question_section_button_call = "index-module--frequently_question_still_question_section_button_call--7f65f";
export var frequently_question_still_question_section_img = "index-module--frequently_question_still_question_section_img--9f310";
export var frequently_question_still_question_section_img_list = "index-module--frequently_question_still_question_section_img_list--f2e3d";
export var frequently_question_still_question_section_para = "index-module--frequently_question_still_question_section_para--1fd2a";
export var frequently_question_still_question_section_title = "index-module--frequently_question_still_question_section_title--0af89";