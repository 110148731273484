// extracted by mini-css-extract-plugin
export var com_cel = "index-module--com_cel--39050";
export var form_box = "index-module--form_box--83dec";
export var form_box_input = "index-module--form_box_input--bde62";
export var form_box_input_bottom = "index-module--form_box_input_bottom--95df4";
export var form_box_label = "index-module--form_box_label--c7a68";
export var form_boxs = "index-module--form_boxs--5086f";
export var last_information_email_button = "index-module--last_information_email_button--f847d";
export var last_information_section = "index-module--last_information_section--737a8";
export var last_information_section_box = "index-module--last_information_section_box--761f3";
export var last_information_section_box_button_cel = "index-module--last_information_section_box_button_cel--b2c1b";
export var last_information_section_box_content = "index-module--last_information_section_box_content--90165";
export var last_information_section_box_content_parm = "index-module--last_information_section_box_content_parm--9463b";
export var last_information_section_box_content_title = "index-module--last_information_section_box_content_title--9d103";
export var last_information_section_box_img = "index-module--last_information_section_box_img--7f337";
export var selected = "index-module--selected--9d696";