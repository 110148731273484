import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import next3zeros_contact_us_map from "../../../images/contact_us/location/next3zeros_contact_us_map.png";
import * as he from "./index.module.scss";

const LocationSection = () => {

    return (
        <section>
            <div>
                <div className={he.mapouter}>
                    <div className={he.gmap_canvas}>
                        <iframe className={he.gmap_iframe} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
                                src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=AT Digital pvt, maharagma &amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                        <a href="https://piratebay-proxys.com/">Piratebay</a>
                    </div>
                </div>
            </div>
            {/*<img className={he.location_section} src={next3zeros_contact_us_map} />*/}
        </section>
    );
};

export default LocationSection;
